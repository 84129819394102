import {
  Company,
  Person,
  PreRegistrationAttendee,
  PreRegistrationGroup,
  Visitor,
} from "@/types";
import { create } from "zustand";

export enum VisitType {
  PERSON = "person",
  COMPANY = "company",
  PRE_REG = "pre-reg",
}

export type VisitStore = {
  entranceName: string;
  setEntranceName: (name: string) => void;
  buildingName: string;
  setBuildingName: (name: string) => void;
  appLanguage: string;
  setAppLanguage: (appLanguage: string) => void;
  visitType: VisitType;
  setVisitType: (visitType: VisitType) => void;
  visitor: Visitor;
  setVisitorInfo: (visitor: Visitor) => void;
  hostPerson: Person;
  setHostPersonInfo: (person: Person) => void;
  hostGroup: PreRegistrationGroup | undefined;
  preRegisteredPerson: PreRegistrationAttendee | undefined;
  setPreRegisteredPerson: (person: PreRegistrationAttendee | undefined) => void;
  setSelectedGroup: (group: PreRegistrationGroup) => void;
  hostCompany: Company | undefined;
  setSelectedCompany: (company: Company) => void;
  resetWizard: () => void;
};

export const useVisitorStore = create<VisitStore>((set) => ({
  entranceName: "",
  setEntranceName: (entranceName: string) => set({ entranceName }),
  buildingName: "",
  setBuildingName: (buildingName: string) => set({ buildingName }),
  appLanguage: "no",
  setAppLanguage: (appLanguage: string) => set({ appLanguage }),
  visitType: VisitType.PERSON,
  setVisitType: (visitType: VisitType) => set({ visitType }),
  visitor: {
    name: "",
    phoneNumber: "",
    company: "",
    message: "",
  },
  setVisitorInfo: (visitor: Visitor) =>
    set({
      visitor,
    }),
  hostPerson: {
    userId: "",
    firstName: "",
    lastName: "",
  },
  setHostPersonInfo: (hostPerson: Person) => set({ hostPerson }),
  hostGroup: undefined,
  setSelectedGroup: (hostGroup: PreRegistrationGroup) => set({ hostGroup }),
  preRegisteredPerson: undefined,
  setPreRegisteredPerson: (
    preRegisteredPerson: PreRegistrationAttendee | undefined,
  ) => set({ preRegisteredPerson }),
  hostCompany: undefined,
  setSelectedCompany: (company: Company) =>
    set({
      hostCompany: company,
    }),
  resetWizard: () =>
    set(() => ({
      appLanguage: "no",
      visitType: VisitType.PERSON,
      visitor: {
        name: "",
        phoneNumber: "",
        company: "",
        message: "",
      },
      hostPerson: {
        userId: "",
        firstName: "",
        lastName: "",
      },
      hostGroup: undefined,
      preRegisteredPerson: undefined,
      hostCompany: undefined,
    })),
}));

export interface RequestBody {
  visitor: Visitor;
  visitorLanguage: string;
  language: string;
  hostId: string;
  hostGroup: PreRegistrationGroup | undefined;
  buildingTenantId: string;
  entranceId: string;
  realEstateName: string;
  buildingTenantName: string;
  entranceName: string;
  domain: string;
}

type RequestBodyStore = {
  requestBody: RequestBody;
  setRequestBody: (request: RequestBody) => void;
  resetWizard: () => void;
};

export const useRequestBodyStore = create<RequestBodyStore>((set) => ({
  requestBody: {
    visitor: {
      name: "",
      phoneNumber: "",
      company: "",
      message: "",
    },
    visitorLanguage: "no",
    language: "no",
    hostId: "",
    hostGroup: undefined,
    buildingTenantId: "",
    entranceId: "",
    realEstateName: "",
    buildingTenantName: "",
    entranceName: "",
    domain: "",
  },
  setRequestBody: (requestBody: RequestBody) => {
    set({
      requestBody,
    });
  },
  resetWizard: () =>
    set(() => ({
      requestBody: {
        visitor: {
          name: "",
          phoneNumber: "",
          company: "",
          message: "",
        },
        visitorLanguage: "no",
        language: "no",
        hostId: "",
        hostGroup: undefined,
        buildingTenantId: "",
        entranceId: "",
        realEstateName: "",
        buildingTenantName: "",
        entranceName: "",
        domain: "",
      },
    })),
}));
