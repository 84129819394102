import { useVisitorStore } from "@/lib/store";
import { useEffect } from "react";

export default function TitleUpdater() {
  const { entranceName, buildingName } = useVisitorStore();

  useEffect(() => {
    if (entranceName && buildingName) {
      document.title = `Visit - ${entranceName} | ${buildingName}`;
    } else {
      document.title = "Visit";
    }
  }, [entranceName, buildingName]);

  return null;
}
