"use client";
import { useEffect, useState } from "react";
import styles from "@/styles/RegisterVisitor.module.scss";
import {
  RequestBody,
  useRequestBodyStore,
  useVisitorStore,
  VisitType,
} from "@/lib/store";
import {
  addNorwegianCountryCodeToNorwegianNumbers,
  cleanPhoneNumber,
  isNumberMissingCountryCode,
  validatePhoneNumber,
} from "@/lib/utils";
import { useForm } from "@tanstack/react-form";
import { Button, Icon, InputField, LoadingSpinner } from "@app-components";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import Header from "@/components/Header";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import {
  APP_DOMAIN,
  NAME_REGEX,
  MESSAGE_REGEX,
  COMPANY_REGEX,
  BASE_URL,
  APPEAR_ANIMATION,
} from "@/lib/constants";
import { AnimatePresence, motion } from "framer-motion";

export default function VisitorRegistration() {
  const { t, i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const {
    visitType,
    hostPerson,
    hostCompany,
    hostGroup,
    preRegisteredPerson,
    entranceName,
    buildingName,
    setVisitorInfo,
    setPreRegisteredPerson,
    setSelectedGroup,
  } = useVisitorStore();
  const { setRequestBody, resetWizard } = useRequestBodyStore();
  const [danger, setDanger] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);

  const phoneNumberValidator = (phoneNumber: string) => {
    if (!phoneNumber) {
      return t("Registration.Form.Phone.Errors.Required");
    }
    const cleanedNumber = cleanPhoneNumber(phoneNumber);

    if (!validatePhoneNumber(cleanedNumber)) {
      if (isNumberMissingCountryCode(cleanedNumber)) {
        return t("Registration.Form.Phone.Errors.MissingCountryCode");
      }
      setDanger(true);
      return "";
    }
    setDanger(false);
    return undefined;
  };

  const form = useForm({
    defaultValues: {
      phoneNumber: "",
      name: "",
      visitorCompany: "",
      messageFromVisitor: "",
    },
    onSubmit: async ({ value }) => {
      setFormIsSubmitted(true);
      const visitor = {
        phoneNumber: addNorwegianCountryCodeToNorwegianNumbers(
          value.phoneNumber,
        ),
        name: value.name.trim(),
        company: value.visitorCompany,
        message: value.messageFromVisitor,
      };
      setVisitorInfo(visitor);
      const requestBody: RequestBody = {
        visitor,
        visitorLanguage: i18n.language,
        language: hostPerson.language || "no",
        hostId: hostPerson.userId,
        hostGroup,
        buildingTenantId: hostCompany!.buildingTenantId,
        entranceId: localStorage.getItem("entranceId") as string,
        realEstateName: buildingName,
        buildingTenantName: hostCompany!.buildingTenantName,
        entranceName: entranceName,
        domain: APP_DOMAIN,
      };
      setRequestBody(requestBody);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };

      try {
        const registerVisitor = fetch(
          `${BASE_URL}/registerVisitor?visit-type=${visitType}${preRegisteredPerson ? "&user-was-pre-reg=true" : ""}`,
          requestOptions,
        );
        const delayPromise = new Promise((resolve) => {
          setTimeout(resolve, 1000); // Ensure submit takes at least 1 second for animation to finish
        });
        const [response] = await Promise.all([registerVisitor, delayPromise]);
        if (response.ok) {
          const resData = await response.json();
          if (hostGroup && resData.description)
            setSelectedGroup({
              ...hostGroup,
              eventDescription: resData.description,
            });
          resetWizard();
          setLocation("/registeredsuccess");
        } else {
          setLocation("/registeredfail");
        }
      } catch (error) {
        console.error(error);
        setLocation("/registeredfail");
      }
    },
    validatorAdapter: zodValidator(),
  });

  useEffect(() => {
    if (visitType === VisitType.PRE_REG) {
      const phoneInputValue = form.getFieldValue("phoneNumber");
      if (
        !phoneInputValue ||
        phoneNumberValidator(phoneInputValue) !== undefined
      ) {
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      const delayDebounceFn = setTimeout(async () => {
        try {
          const encodedPhoneNumber = encodeURIComponent(
            addNorwegianCountryCodeToNorwegianNumbers(phoneInputValue),
          );
          const res = await fetch(
            `${BASE_URL}/getPreRegisteredUser?groupId=${hostGroup?.id}&phoneNumber=${encodedPhoneNumber}`,
          );
          const json = await res.json();
          if (!res.ok) throw new Error(json);
          setPreRegisteredPerson(json);
          form.setFieldValue("name", json.name);
          form.validateField("name", "change");
        } catch (error) {
          console.error(error);
          setPreRegisteredPerson(undefined);
        } finally {
          setIsSearching(false);
        }
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [visitType, form.state.values.phoneNumber]);

  return (
    <main className={styles.main}>
      <Header step={3} />
      <div className={styles.visitorInfoForm}>
        <h2>
          <Trans i18nKey="Registration.Header">
            Text <strong>text</strong>...
          </Trans>
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <form.Field
            name="phoneNumber"
            validators={{
              onChange: ({ value }) => phoneNumberValidator(value),
            }}
          >
            {(field) => (
              <InputField
                autoFocus
                type="tel"
                danger={danger}
                label={t("Registration.Form.Phone.Label")}
                icon="phone_android"
                placeholder={t("Registration.Form.Phone.Placeholder")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                large
                required
                append={isSearching ? <LoadingSpinner /> : undefined}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="name"
            validators={{
              onChange: z
                .string()
                .min(1, t("Registration.Form.Name.Errors.Required"))
                .min(2, t("Registration.Form.Name.Errors.MinLength"))
                .max(
                  30,
                  t("Registration.Form.Errors.MaxLength", {
                    field: t("Registration.Form.Fields.Name"),
                    number: "30",
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t(
                    "Registration.Form.Name.Errors.ForbiddenCharacters",
                  ),
                }),
            }}
          >
            {(field) => (
              <InputField
                label={t("Registration.Form.Name.Label")}
                icon="person"
                placeholder={t("Registration.Form.Name.Placeholder")}
                value={field.state.value}
                disabled={!!preRegisteredPerson}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                large
                dangerText={field.state.meta.errors.toString()}
                required
              />
            )}
          </form.Field>
          <AnimatePresence mode="wait" initial={false}>
            {!preRegisteredPerson ||
            !form.state.values.phoneNumber ||
            danger ||
            isSearching ? (
              <motion.div
                key="input-fields"
                initial={APPEAR_ANIMATION.from}
                animate={APPEAR_ANIMATION.to}
                exit={APPEAR_ANIMATION.from}
                className={`${styles.fullWidth} ${styles.subGrid}`}
              >
                <form.Field
                  name="visitorCompany"
                  validators={{
                    onChange: z
                      .string()
                      .max(
                        30,
                        t("Registration.Form.Errors.MaxLength", {
                          field: t("Registration.Form.Fields.CompanyName"),
                          number: "30",
                        }),
                      )
                      .trim()
                      .refine((value) => COMPANY_REGEX.test(value), {
                        message: t(
                          "Registration.Form.Company.Errors.ForbiddenCharacters",
                        ),
                      })
                      .optional(),
                  }}
                >
                  {(field) => (
                    <InputField
                      label={t("Registration.Form.Company.Label")}
                      icon="work"
                      placeholder={t("Registration.Form.Company.Placeholder")}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      large
                      dangerText={field.state.meta.errors.toString()}
                    />
                  )}
                </form.Field>
                {visitType !== VisitType.PRE_REG && (
                  <form.Field
                    name="messageFromVisitor"
                    validators={{
                      onChange: z
                        .string()
                        .max(
                          100,
                          t("Registration.Form.Errors.MaxLength", {
                            field: t("Registration.Form.Fields.Message"),
                            number: "100",
                          }),
                        )
                        .trim()
                        .refine((value) => MESSAGE_REGEX.test(value), {
                          message: t(
                            "Registration.Form.Message.Errors.ForbiddenCharacters",
                          ),
                        })
                        .optional(),
                    }}
                  >
                    {(field) => (
                      <InputField
                        label={t("Registration.Form.Message.Label")}
                        icon="sms"
                        placeholder={t("Registration.Form.Message.Placeholder")}
                        value={field.state.value}
                        onBlur={field.handleBlur}
                        onChange={(e) => field.handleChange(e.target.value)}
                        large
                        dangerText={field.state.meta.errors.toString()}
                      />
                    )}
                  </form.Field>
                )}
              </motion.div>
            ) : (
              <motion.p
                key="pre-reg-msg"
                initial={APPEAR_ANIMATION.from}
                animate={APPEAR_ANIMATION.to}
                exit={APPEAR_ANIMATION.from}
                className={`${styles.fullWidth} ${styles.preRegMsg}`}
              >
                <Icon name="done" />
                {t("Registration.Form.PreRegMsg")}
              </motion.p>
            )}
          </AnimatePresence>
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
          >
            {([canSubmit, isSubmitting]) => (
              <div className={styles.submitButtonContainer}>
                <Button
                  success
                  type="submit"
                  disabled={!canSubmit || isSubmitting || danger}
                  isLoading={isSubmitting}
                  large
                >
                  {t("Registration.Form.Submit", {
                    hostFirstName:
                      visitType === VisitType.PERSON
                        ? hostPerson.firstName
                        : visitType === VisitType.PRE_REG
                          ? t("Registration.Form.Host")
                          : "",
                  })}
                </Button>
                {formIsSubmitted && (
                  <motion.div
                    className={styles.expandingCircle}
                    initial={{ scale: 0 }}
                    animate={{ scale: 100 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                  />
                )}
              </div>
            )}
          </form.Subscribe>
        </form>
      </div>
    </main>
  );
}
