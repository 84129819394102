import { Person } from "@/types";
import { InputField } from "@app-components";
import { useState, useEffect } from "react";
import styles from "@/styles/PersonSelector.module.scss";
import { useLocation } from "wouter";
import { Trans, useTranslation } from "react-i18next";
import HostCard from "@/components/HostCard";
import Loader from "@/components/Loader";
import { motion, AnimatePresence } from "framer-motion";
import { useVisitorStore, VisitType } from "@/lib/store";
import FrontDeskButton from "./FrontDeskButton";

const minCharToSearch = 3;
const animateFrom = { opacity: 0, y: 30 };
const animateTo = { opacity: 1, y: 0 };

interface IPersonSelectorProps {
  users: Person[] | undefined;
  isLoading: boolean;
}

export default function PersonSelector({
  users,
  isLoading,
}: IPersonSelectorProps) {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { setHostPersonInfo, setVisitType } = useVisitorStore();
  const [query, setQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<Person[]>([]);

  useEffect(() => {
    if (users) {
      const filteredData = users.filter((user: Person) => {
        // Return user if fullname starts with search query
        const fullName = `${user.firstName} ${user.lastName}`;
        return fullName.toLowerCase().includes(query.toLowerCase());
      });
      setFilteredUsers(filteredData);
    } else {
      setFilteredUsers([]);
    }
  }, [query, users]);

  const setSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value || "");
  };

  const clearSearchQuery = () => {
    setQuery("");
  };

  const registration = (host: Person) => {
    setVisitType(VisitType.PERSON);
    setHostPersonInfo(host);
    setLocation("/registervisitor");
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <InputField
          autoFocus
          label={""}
          value={query}
          icon="search"
          placeholder={t("FindPersonOrCompany.FindPersonPlaceholderShort")}
          onChange={setSearchQuery}
          onClear={clearSearchQuery}
        />
      </div>
      <div style={{ position: "relative" }}>
        <AnimatePresence mode="wait" initial={false}>
          {query.length < minCharToSearch ? (
            <motion.div
              key="placeholder"
              initial={animateFrom}
              animate={animateTo}
              exit={animateFrom}
              className={styles.placeholderContainer}
            >
              <div className={styles.grid}>
                {[1, 2, 3, 4].map((num) => (
                  <HostCard key={num} hostName="" aria-hidden />
                ))}
              </div>
              <div className={styles.overlayGradient}></div>
              <div className={styles.placeholderText}>
                <h2>{t("PersonsPlaceholder.Line1")}</h2>
                <p>{t("PersonsPlaceholder.Line2")}</p>
              </div>
            </motion.div>
          ) : isLoading ? (
            <motion.div
              key="loading"
              initial={animateFrom}
              animate={animateTo}
              exit={animateFrom}
              className={`center ${styles.loadingContainer}`}
            >
              <Loader />
            </motion.div>
          ) : filteredUsers.length ? (
            <motion.div
              key="results"
              initial={animateFrom}
              animate={animateTo}
              exit={animateFrom}
              className={styles.grid}
            >
              {filteredUsers.map((user: Person) => (
                <HostCard
                  key={user.userId}
                  hostName={`${user.firstName} ${user.lastName}`}
                  onClick={() => registration(user)}
                />
              ))}
            </motion.div>
          ) : (
            <motion.div
              key="no-results"
              initial={animateFrom}
              animate={animateTo}
              exit={animateFrom}
              className={styles.placeholderContainer}
            >
              <div
                className={`${styles.placeholderText} ${styles.noResultsText}`}
              >
                <h2>{t("PersonsNoResults.Line1")}</h2>
                <p>
                  <Trans i18nKey="PersonsNoResults.Line2">
                    Text <strong>text</strong> text
                  </Trans>
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <FrontDeskButton />
      </div>
    </>
  );
}
